import { useEffect, useRef, useState } from "react";
import { ARAnchorType, ARContent, ARContentType, GroupObject, Poi, useARContentsData, usePoisData, useProjectData, Vector3 } from "../data";
import { createOrUpdateARContent, deleteARContent } from "apis/arContent";
import { useNavigate } from "react-router-dom";
import { getARContentChildsFromParentId, updateArContentsFromStep, getCoordinateStepBody, getStepType, getTransformStepBody } from "./utils";
import { Dispatch } from "redux";
import { changeSelectedArContent, changeSelectedPoi } from "store/actions";
import { publishProject } from "apis/project";
import { createOrUpdatePoi, deletePoi } from "apis/poi";
import { EditorState } from "store/editor/types";
import { useObjectSelectProvider } from "./groupHook";

export enum EditorStepType {
    ArContentPositionChange = "ArContentPositionChange",
    ArContentRotationChange = "ArContentRotationChange",
    ArContentScaleChange = "ArContentScaleChange",
    ArContentCoordinateChange = "ArContentCoordinateChange",
    GroupTransformChange = "GroupTransformChange",
}
export interface EditorStep {
    type: EditorStepType
    transform?: Vector3 | null
    groupTransform?: GroupObject
    coordinate?: number[]
    targetContents: string[]
}

let arContentsSync: ARContent[] = []

export const useEditorState = (
    projectId: string | undefined,
    faceAnchorId: string | null,
    detectionImageId: string | null,
    pointCloudId: string | null,
    shouldAllowAccess: boolean,
    dispatch: Dispatch,
    editorState: EditorState,
) => {
    const [project] = useProjectData(projectId!);
    const [pastState, setPastState] = useState<EditorStep[]>([]);
    const [futureState, setFutureState] = useState<EditorStep[]>([]);
    const [step, setStep] = useState(0);
    const stepRef = useRef(0)
    const [displayArContents, setDisplayArContents] = useState<ARContent[]>([]);
    const [arContents, setARContents] = useState<ARContent[]>([]);
    const [displayPois, setDisplayPois] = useState<Poi[]>([]);
    const [pois, setPois] = useState<Poi[]>([]);
    const arContentsRef = useRef<ARContent[]>([])
    const [fetchARContents, refreshArContent] = useARContentsData(projectId!);
    const [fetchPois, refreshPois] = usePoisData(projectId || "", pointCloudId || "")

    const onAddGroupStep = (newPastStep: EditorStep, newFutureStep: EditorStep) => {
        handleContentChange(newPastStep, newFutureStep)
    }
    const { groupContents, updateGroupContentFromStep } = useObjectSelectProvider(
        editorState,
        dispatch,
        arContents,
        (objs) => { setARContents(objs) },
        onAddGroupStep,
    )
    const navigate = useNavigate();

    const isGroupSelection = editorState.selectedObjects.length > 1

    const resetStep = () => {
        setStep(0)
        setPastState([])
        setFutureState([])
        stepRef.current = 0
    }

    const resetArContent = () => {
        arContentsSync = []
        dispatch(changeSelectedArContent(""))
        setARContents([])
        setDisplayArContents([])
    }

    const onAddStep = (previousArContent: ARContent, arContent: ARContent) => {
        const stepType = getStepType(previousArContent, arContent)
        if (!stepType) return
        const newPastStep: EditorStep = {
            type: stepType,
            transform: getTransformStepBody(stepType, previousArContent),
            coordinate: getCoordinateStepBody(previousArContent),
            targetContents: [arContent._id || ""]
        }
        const newFutureStep: EditorStep = {
            type: stepType,
            transform: getTransformStepBody(stepType, arContent),
            coordinate: getCoordinateStepBody(arContent),
            targetContents: [arContent._id || ""]
        }
        handleContentChange(newPastStep, newFutureStep)
    }

    const handleContentChange = (newPastStep: EditorStep, newFutureStep: EditorStep) => {
        const currentStep = stepRef.current
        setPastState((prevStepState) => {
            const newStepState = prevStepState.slice(0, currentStep)
            newStepState.push(newPastStep)
            if (newStepState.length >= 20) {
                newStepState.splice(0, 10)
            }
            const newStepIndex = newStepState.length
            setStep(newStepIndex)
            stepRef.current = newStepIndex
            return newStepState
        })
        setFutureState((prevStepState) => {
            const newStepState = prevStepState.slice(0, currentStep)
            newStepState.push(newFutureStep)
            if (newStepState.length >= 20) {
                newStepState.splice(0, 10)
            }
            return newStepState
        })
    }

    const onUndo = () => {
        const stepToRealize = step - 1
        if (stepToRealize < 0 || stepToRealize > pastState.length - 1 || pastState.length === 0) return
        handleStepChange(stepToRealize, pastState)
        setStep(stepToRealize)
        stepRef.current = stepToRealize
    }
    const onRedo = () => {
        const stepToRealize = step
        if (stepToRealize < 0 || stepToRealize > futureState.length - 1 || futureState.length === 0) return
        handleStepChange(stepToRealize, futureState)
        setStep(stepToRealize + 1)
        stepRef.current = stepToRealize + 1
    }

    const enableUndo = pastState.length !== 0 && step > 0
    const enableRedo = futureState.length !== 0 && step < futureState.length

    const handleStepChange = (newStep: number, stepState: EditorStep[]) => {
        const stepToRealize = stepState[newStep]
        if (stepToRealize.type === EditorStepType.GroupTransformChange) {
            updateGroupContentFromStep(stepToRealize)
            return
        }
        setARContents(prevObjs => {
            const newObjs = updateArContentsFromStep(stepToRealize, prevObjs)
            arContentsSync = newObjs
            return newObjs;
        });
    }

    const handleUpdateARContent = (index: number, obj: ARContent) => {
        setARContents(prevObjs => {
            onAddStep(prevObjs[index], obj)
            const newObjs = [...prevObjs];
            newObjs[index] = obj;
            arContentsSync = newObjs
            return newObjs;
        });
        setDisplayArContents(prevObjs => {
            const newObjs = [...prevObjs];
            newObjs[index] = obj;
            return newObjs;
        })
        createOrUpdateARContent(obj);
    }

    const handleUpdateARContentState = (index: number, obj: ARContent) => {
        if (arContentsSync.length === 0) return
        setDisplayArContents(prevObjs => {
            const newObjs = [...prevObjs];
            newObjs[index] = obj;
            return newObjs;
        })
    }

    const handleAddARContentToState = (obj: ARContent) => {
        arContentsSync.push(obj)
        const newObjs = [...arContentsSync];
        setARContents(newObjs);
        resetStep()
        dispatch(changeSelectedArContent(obj._id || ""))
    }

    const handleRemoveARContent = async (content: ARContent) => {
        dispatch(changeSelectedArContent(""))
        const res = await deleteARContent(content);
        if (!res) return

        let childs = getARContentChildsFromParentId(arContents, content.arContentId);
        childs.forEach(async child => {
            await deleteARContent(child);
        });
        resetStep()
        const newArContents = arContentsSync.filter(item => item.arContentId !== content.arContentId)
        setARContents(newArContents);
        arContentsSync = newArContents

        if (content.arContentType !== ARContentType.DetectionImage && content.arContentType !== ARContentType.FaceParent) return

        const anchorType = project.data.lastOpenedAnchorType

        let urlParams = `?anchor_type=${anchorType}`;
        if ((content.arContentType !== ARContentType.DetectionImage) && (content.arAnchorType === ARAnchorType.ImageAnchor)) {
            urlParams += `&detection_image_id=${detectionImageId}`;
            navigate(urlParams);
        }
        if ((content.arContentType !== ARContentType.FaceParent) && (content.arAnchorType === ARAnchorType.FaceAnchor)) {
            urlParams += `&face_anchor_id=${faceAnchorId}`;
            navigate(urlParams);
        }
        if (content.arContentType === ARContentType.FaceParent
            || content.arContentType === ARContentType.DetectionImage) {
            navigate(urlParams);
        }
    }

    const handleUpdatePoi = (index: number, obj: Poi) => {
        setPois(prevObjs => {
            const newObjs = [...prevObjs];
            newObjs[index] = obj;
            return newObjs;
        });
        setDisplayPois(prevObjs => {
            const newObjs = [...prevObjs];
            newObjs[index] = obj;
            return newObjs;
        })
        createOrUpdatePoi(obj);
    }

    const handleUpdatePoiState = (index: number, obj: Poi) => {
        setDisplayPois(prevObjs => {
            const newObjs = [...prevObjs];
            newObjs[index] = obj;
            return newObjs;
        })
    }

    const handleCreatePoi = async (newPoi: Poi) => {
        if (!projectId || !pointCloudId) return
        const id = await createOrUpdatePoi(newPoi)
        newPoi._id = id
        const newPois = [...pois];
        newPois.push(newPoi);
        setPois(newPois);
        dispatch(changeSelectedPoi(newPoi._id))
    }

    const handleRemovePoi = async (poi: Poi) => {
        dispatch(changeSelectedPoi(""))
        const res = await deletePoi(poi);
        if (!res) return
        setPois(pois.filter(item => item._id !== poi._id));
    }

    const populateArContentFromFetchData = (fetchArContents: ARContent[]) => {
        let contents = fetchArContents.filter((content) => content.arAnchorType === project.data.lastOpenedAnchorType);
        setARContents(contents);
        arContentsSync = contents
    }

    const handlePublishProject = async () => {
        if (!project.data._id) {
            return
        }
        resetArContent()
        const publishedContent = await publishProject(project.data._id)
        populateArContentFromFetchData(publishedContent)
        resetStep()
    }

    const handleEditorRefresh = () => {
        resetArContent()
        refreshArContent()
        resetStep()
    }

    useEffect(() => {
        arContentsRef.current = arContents
        setDisplayArContents(arContents)
    }, [arContents])

    useEffect(() => {
        setDisplayPois(pois)
    }, [pois])

    useEffect(() => {
        if (project.isFinished && fetchARContents.isFinished) {
            if (!project.data._id) {
                navigate('/');
            }
            populateArContentFromFetchData(fetchARContents.data)
        }
    }, [navigate, project, fetchARContents]);

    useEffect(() => {
        if (project.isFinished && fetchPois.isFinished) {
            setPois(fetchPois.data);
        }
    }, [navigate, project, fetchPois]);

    useEffect(() => {
        if (!shouldAllowAccess) {
            // navigate('/projects')
            // TODO: Fix this in GC-352
        }
    })

    return {
        onUndo,
        onRedo,
        enableUndo,
        enableRedo,
        handleUpdateARContent,
        handleUpdateARContentState,
        handleAddARContentToState,
        handleRemoveARContent,
        handleCreatePoi,
        handleUpdatePoi,
        handleUpdatePoiState,
        handleRemovePoi,
        handlePublishProject,
        handleEditorRefresh,
        project,
        displayArContents: isGroupSelection && groupContents !== null ? groupContents : displayArContents,
        displayPois,
    };
};