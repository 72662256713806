import React, { useState } from "react"
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import { Poi } from "../../../data";
import { Box } from "components/Common/Layout/Layout";
import { ApplicationState } from "store/data";
import { connect } from "react-redux";
import { EditorState, SelectedObjectType } from "store/editor/types";
import { Dispatch } from "redux";
import { changeSelectedPoi } from "store/actions";
import { LiteButton } from "components/Common/LiteButton/LiteButton";

const PoiItem = ({
    object,
    isSelected,
    onClick,
    onDuplicatePoi,
}: {
    object: Poi
    isSelected: boolean
    onClick: () => void
    onDuplicatePoi: () => void
}) => {
    const [popoverOpen, setPopoverOpen] = useState(false)
    const togglePopover = () => {
        setPopoverOpen(!popoverOpen)
    }

    return <div className="mb-1" data-testid={`poi-item-${isSelected ? 'selected' : 'normal'}`}>
        <LiteButton
            onClick={onClick}
            highlight={isSelected}
            border="solid"
            block
        >
            <Box display="flex" justifyContent="space-between">
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItem="center"
                >
                    <div className='me-3'><i className="font-size-16 bi bi-geo-alt text-primary" /></div>
                    <div className="text-secondary fs-6">{object.displayName || "New Poi"}</div>
                </Box>
                {
                    <Dropdown
                        isOpen={popoverOpen}
                        toggle={togglePopover}
                        direction="end"
                    >
                        <DropdownToggle
                            tag='i'
                            className={`font-size-16 align-middle bi bi-three-dots-vertical text-dark text-opacity-75 ms-1`}
                            data-testid="poi-dropdown-toggle"
                            onClick={(e) => {
                                e.stopPropagation()
                                togglePopover()
                            }}
                        />
                        <DropdownMenu>
                            <DropdownItem tag='div' data-testid="poi-dropdown-duplicate" onClick={onDuplicatePoi}>
                                Make a Copy
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                }
            </Box>
        </LiteButton>
    </div>
}

const filterPoiToDisplay = (pois: Poi[], editorState: EditorState): Poi[] => {
    const selectedFloor = editorState.selectedFloor
    const poisToDisplay = pois.filter(poi => {
        if (selectedFloor) {
            return selectedFloor._id === poi.floorId ||
                (editorState.selectedObjectType === SelectedObjectType.Poi && poi._id === editorState.selectedObjects[0]?.id) ||
                (!poi.floorId && editorState.selectedFloor?.isDefault)
        }
        return true
    })
    return poisToDisplay
}

interface OwnProps {
    pois: Poi[]
    onAddPoi: () => void
    onDuplicatePoi: (poi: Poi) => void
}

interface StateProps {
    editorState: EditorState
}

interface DispatchProps {
    dispatch: Dispatch
}

type PoiMenuProps = OwnProps & StateProps & DispatchProps

const PoiMenuComponent = ({ pois, editorState, onAddPoi, onDuplicatePoi, dispatch }: PoiMenuProps) => {

    const poisToDisplay = filterPoiToDisplay(pois, editorState)
    const isFloorSelected = !!editorState.selectedFloor

    return (
        <React.Fragment>
            <Button
                color="secondary"
                outline
                block
                disabled={!isFloorSelected}
                onClick={onAddPoi}
                data-testid="add-poi-button"
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItem="center"
                >
                    <div className='me-2'><i className="font-size-16 bi bi-plus-lg" /></div>
                    <div>Add POI</div>
                </Box>
            </Button>
            {!isFloorSelected && <p className="text-danger">Please select a floor before adding POI</p>}
            {/* Show List AR Contents */}
            <div className="mb-5 mt-2">
                <div className="accordion" id="accordion">
                    {
                        poisToDisplay.length === 0
                            ? <p className="text-dark">There is no poi created yet. Please create a poi.</p>
                            : poisToDisplay.map((poi, index) => (
                                <React.Fragment key={"anchor-row-" + index}>
                                    {
                                        <PoiItem
                                            object={poi}
                                            onClick={() => {
                                                dispatch(changeSelectedPoi(poi._id || ""))
                                            }}
                                            isSelected={editorState.selectedObjectType === SelectedObjectType.Poi && poi._id === editorState.selectedObjects[0]?.id}
                                            onDuplicatePoi={() => {
                                                onDuplicatePoi(poi)
                                            }}
                                        />
                                    }
                                </React.Fragment>
                            ))}
                </div>
            </div>
        </React.Fragment>
    )
}

PoiMenuComponent.displayName = "PoiMenuComponent";

const mapStateToProps = (state: ApplicationState): StateProps => ({
    editorState: state.Editor,
})

const PoiMenu = connect(mapStateToProps)(PoiMenuComponent);

export { PoiMenu, PoiMenuComponent, filterPoiToDisplay }